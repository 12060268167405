import './index.scss';
import nextChapterImage from '../../../assets/images/articleImages/chapterimg.png';
import Button from '../../Shared/Button';
import { useHistory } from 'react-router-dom';

const Nextarticle = (props: any) => {
  let history = useHistory();
  const handleClick = (nextArticleUrl) => {
    history.push(nextArticleUrl, { cat: nextArticleUrl });
  };
  return (
    <div onClick={() => handleClick(props.nextArticleUrl)} className="NextArticle" data-testid="nextArticleUrl">
      <div className="">
        {' '}
        {props.hasNextArticle && (
          <>
            <h6>Next article</h6>
            <p>{props.nextArticleTitle}</p>
          </>
        )}
      </div>
      <div className="Feedback-chapter">
        <Button className="Button Button--transparentBlack Button--arrow" onClick={props.handleCampaign}>
          {props.hasNextArticle ? 'Read' : props.pageType === 'LearnPage' ? 'Back to Learn' : 'Back to Sustainability'}
        </Button>

        {/* <div className="Link Link--arrow Feedback-chapterLink">
          <img src={nextChapterImage} className="" />
          <button className="Button Button--transparent"> {props.hasNextArticle ? 'Read' : 'Back to Learn'} </button>
        </div> */}
      </div>
    </div>
  );
};

export default Nextarticle;
